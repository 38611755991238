import React from 'react'; 
import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';

//Import sections 
import Mission from '../pages/mission/Mission';
import Contact from '../pages/contact/Contact';
import Gallery from '../pages/gallery/Gallery';
import Home from '../pages/home/Home';
import Story from '../pages/story/Story';
import Events from '../pages/events/events';
import Social from '../pages/social/Social';

const Layout = ({children}) => {
    return(
        <div className="main-container">
            <Header />

            <main className="main-content" id="main-page-content">
                <Outlet />
            </main>

            {/* <Footer />  */}
        </div>
    )
}

export default Layout; 