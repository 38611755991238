import React from 'react'; 
import SectionHeader from '../../components/SectionHeader';

// @TODO: Make it a separate page 

const Social = () => {

    return(
        <div style={{
            marginTop: '2em'
        }}>
            <SectionHeader HeaderTitle={"Social"}/>
            <div
                style={{
                margin: "4em",
                textAlign: "center"
                }}
            >
                Social media will be here... 
            </div>
        </div>
    )
}

export default Social;