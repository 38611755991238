import React from "react"; 
import { motion } from "framer-motion";

const SectionHeader = ({HeaderTitle}) => {
    return(
        <motion.div className="section-header"
            initial={{ opacity: 0, x: -25 }}
            whileInView={{ opacity: 1, x: 0 }} 
            transition={{duration: 1}}
            viewport={{amount: 0.5, once: false}}
        >
            <span>
                {HeaderTitle}
            </span>
        </motion.div>
    )
}

export default SectionHeader; 