import CMS from 'decap-cms-app'; 
import { useEffect } from 'react';
import netlifyIdentity from 'netlify-identity-widget';

import config from './config'; 

const Admin = () => {
    useEffect(() => {
        netlifyIdentity.init({
            APIUrl: 'https://healingwatersforwarriors.com/.netlify/identity',
        });

         // Register event listeners for login/signup
         netlifyIdentity.on('login', user => {
            console.log('Login successful:', user);
            CMS.registerPreviewStyle(); // Initialize CMS after login
            netlifyIdentity.close(); // Close the widget after login/signup
        });

        netlifyIdentity.on('logout', () => {
            console.log('Logged out');
        });


        if(!window.__CMS_INITIALIZED__) {
            CMS.init({config: config}); 
            window.__CMS_INITIALIZED__ = true;
        }
        
        if(window.location.hash.includes("invite_token")) {
            netlifyIdentity.open(); 
        }

        return () => {
            netlifyIdentity.off('login');
            netlifyIdentity.off('logout');  
        }

       

    }, [])

    const handleLogin = () => {
        netlifyIdentity.open();
    }

 
    return(
        <div>
            <div id="nc-root"></div> 
        </div>
    )
}

export default Admin;  