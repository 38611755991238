import React from 'react'; 
import HeaderImage from '../../assets/main.jpg'; 
import BackgroundImage from '../../assets/river_gorge_sunset.jpg'
import secondimage from '../../assets/new_river_gorge.webp'
import Logo from '../../assets/logo.png';
import test from '../../assets/test.webp'
import underwater from '../../assets/underwater.jpg'
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const get_coverphoto_image = () => {
    const context = require.context('../../../content/coverphoto', true, /\.json$/);
    const keys = context.keys();
    let image = null; 

    keys.forEach((key) => {
        const _image = context(key);
        image = _image.image;
        return _image.image; 
    }
    ); 

    return image; 
}

const Home = () => {

    const navigate = useNavigate(); 

    const handleClick = () => {
        navigate("#contact")
    }

    return(
        <div 
            className="hero-section"
            style={{
                display: 'flex',
                flexDirection: 'column', 
                alignItems: 'center',
                background: `url(${get_coverphoto_image()}) no-repeat center top, linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)`,
                backgroundRepeat: "no-repeat, no-repeat",
                backgroundPosition: "top, top",
                backgroundBlendMode: "multiply, normal",
                backgroundSize: "100% 75%, 100% 76%" 
            }}
        >
            {/* <div 
                className="header-title"
                style={{

            }}>

                    Healing Waters <br />for <br /> Wounded Warriors


                
            </div>
            <div className="section-subheader">
                Maybe some slogan here?
            </div>
            <div className="section-body">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </div> */}
            <motion.div className="hero-header"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }} 
                transition={{duration: 1.5}}  
                viewport={{amount: 0.5}}
            >
                <div className="hero-box">
                    <div className="hero-box-title">
                        Healing Waters For Warriors
                    </div>
                    
                    <div className="hero-box-subtext">
                    Our vision at Healing Waters for Warriors is to offer hope and support to service members, veterans, and first responders. We strive to create a space that promotes healing, education, and reintegration, helping every warrior find renewed purpose and fulfillment.
                    </div>
                    <div className="hero-box-button">
                        <button onClick={handleClick}>Contact Us</button>
                    </div>
                </div>
                
            </motion.div>
        </div>
    )
}

export default Home; 