import React from 'react'; 
import { Button } from 'react-bootstrap';

const DonateButton = () => {

    const onClick = () => {
        window.open("https://www.paypal.com/donate/?hosted_button_id=UTVBMM7EDKFZG", "_blank");
    }
    return(
        <button
            className="component-donate-button"
            style={{
                position: "absolute", 
                right: "1em", 
                top: "1em"
            }}
            onClick={onClick}
        >
            Donate
        </button>
    ); 
}

export default DonateButton; 