import React, { useEffect, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import Logo from '../assets/logo.png';
import DonateButton from '../components/DonateButton';

const Header = () => {
    const [activeLink, setActiveLink] = useState('home');
    const location = useLocation();

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        console.log("Scrolling to: "+sectionId, section)
        if (section) {
            section.scrollIntoView({ behavior: 'smooth'})
            setActiveLink(sectionId);
        }
    };

    useEffect(() => {

        const container = document.getElementById("main-page-content"); 
        const handleScroll = () => {
            const sections = ['home', 'story', 'gallery', 'contact', 'social'];
            const scrollPosition = container.scrollTop + container.offsetHeight / 2;

            sections.forEach((sectionId) => {
                const section = document.getElementById(sectionId);
                if (section && scrollPosition >= section.offsetTop) {
                    setActiveLink(sectionId);
                }
            });
        };

        container.addEventListener('scroll', handleScroll);
        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        console.log("Location pathname: ", location.pathname)
        if (location.pathname === '/') {
            const sectionId = location.hash ? location.hash.replace('#', '') : 'home';
            setTimeout(() => {
                scrollToSection(sectionId);
            }, 100);
        }
    }, [location]);
    return(
        <Navbar expand="lg" 
            variant="dark" 
            className="container-fluid header-nav-bar"
            style={{
                "position": "relative"
            }}
            > 
            <div
                style={{
                    width: '100%'
                }}
            >
                <div>
                    <Navbar.Toggle  
                        aria-controls="basic-navbar-nav" 
                        variant="dark"
                    >
                        <FontAwesomeIcon icon={faBars} color="black"/>
                    </Navbar.Toggle> 
                    <Navbar.Brand href="/"
                        style={{
                            marginLeft: '3rem'
                        }}
                    > 
                        <img 
                            src={Logo}
                            width="100" height="100" 
                            alt="Logo" /> 
                    </Navbar.Brand> 
                    
                </div>
            
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%'
                    }}
                >
                    <div className="navbar-collapse-wrapper">
                        <Navbar.Collapse  
                            id="basic-navbar-nav"> 
                            <Nav className="ml-auto">
                    <Nav.Link
                        as={Link}
                        to="/"
                        className={activeLink === 'home' ? 'active' : ''}
                        onClick={() => scrollToSection('home')}
                    >
                        Home
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to="/#story"
                        className={activeLink === 'story' ? 'active' : ''}
                        onClick={() => scrollToSection('story')}
                    >
                        About
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to="/#gallery"
                        className={activeLink === 'gallery' ? 'active' : ''}
                        onClick={() => scrollToSection('gallery')}
                    >
                        Gallery
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to="/#contact"
                        className={activeLink === 'contact' ? 'active' : ''}
                        onClick={() => scrollToSection('contact')}
                    >
                        Contact
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to="/events"
                        className={activeLink === 'events' ? 'active' : ''}
                        onClick={()=>setActiveLink("events")}
                    >
                        Events
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to="/social"
                        className={activeLink === 'social' ? 'active' : ''}
                        onClick={()=>setActiveLink("social")}
                    >
                        Social
                    </Nav.Link>
                    <Nav.Link
                        as={Link}
                        to="/recognition"
                        className={activeLink === 'recognition' ? 'active' : ''}
                        onClick={()=>setActiveLink("recognition")}
                    >
                        Recognition
                    </Nav.Link>
                </Nav> 
                        </Navbar.Collapse> 
                    </div>
                </div>
            </div>
            
            <DonateButton />
            
        </Navbar>
    )
}

export default Header; 