(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define("DecapCmsApp", ["react", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["DecapCmsApp"] = factory(require("react"), require("react-dom"));
	else
		root["DecapCmsApp"] = factory(root["React"], root["ReactDOM"]);
})(window, (__WEBPACK_EXTERNAL_MODULE__12244__, __WEBPACK_EXTERNAL_MODULE__53741__) => {
return 