import React, {useEffect, useState} from 'react'; 
//import "react-responsive-carousel/lib/styles/carousel.min.css"; 
// import { Carousel } from 'react-responsive-carousel';
import Image_1 from '../../assets/image_1.jpg'; 
import Image_2 from '../../assets/image_2.jpg'; 
import Image_3 from '../../assets/image_3.jpg'; 
import SectionHeader from '../../components/SectionHeader';
import { Carousel } from 'react-bootstrap';
import { motion } from 'framer-motion';


// Get all images from listed in json files from content/gallery folder 
const get_gallery_images = () => {
    const context = require.context('../../../content/gallery', true, /\.json$/);
    const keys = context.keys(); 

    let image_paths = []; 

    keys.forEach((key) => {
        const image = context(key); 
        image_paths.push(image.image); 
    })

    console.log(image_paths);
    return image_paths; 
}

const TestCarousel = () => {
    const [images, setImages] = useState([]);  
    useEffect(() => {

        const _images = get_gallery_images('gallery');  
        setImages(_images);
    }, [])

    return(
        <Carousel data-bs-theme="light">
            {
                images.map((image, index) => (
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={image}
                        alt={`Slide ${index}`}
                        />
                    </Carousel.Item>
                ))
            }
        </Carousel>
    )
}

const Gallery = () => {
    return(
            <div
                style={{
                    // display: 'flex', 
                    // alignItems: "center",
                    // justifyContent: "center",
                    // height: "100%"
                }}
            >
                <SectionHeader HeaderTitle={"gallery"} />
                <motion.div className="section-body"
                    style={{
                        display: "flex",
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: "6rem"
                    }}
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{duration: 1.5, delay: 0.25}}
                    viewport={{once: false}}

                >
                    <TestCarousel />
                    {/* <Carousel
                    style={{
                        maxHeight: "600px"
                    }}
                    showStatus={false}
                    showIndicators={false}
                    showThumbs={false}
                    autoPlay={true}
                    infiniteLoop={true}
                    transitionTime={5000}

                    >
                        <div style={{
                            backgroundColor: "black"
                        }}>
                            <img src={Image_2}/>
                        </div>
                        <div style={{
                            backgroundColor: "black"
                        }}>
                            <img src={Image_3}/>
                        </div>
                        <div style={{
                            backgroundColor: "black"
                        }}>
                            <img src={Image_1}/>
                        </div>
                        
                        
                    </Carousel> */}
                </motion.div>
               
            </div>
            

    )
}

export default Gallery; 