import React from 'react'; 
import SectionHeader from '../../components/SectionHeader';

const Recognition = () => {

    return(
        <div
            style={{
                marginTop: '2em', 
                textAlign: 'center'
            }}
        >
            <SectionHeader HeaderTitle={"Recognition"}/>
            <div
                style={{
                    margin: "4em", 
                    textAlign: "center"
                }}
            >
                Recognition for donors and sponsors will be displayed here...
            </div>
        </div>
    )
}

export default Recognition; 