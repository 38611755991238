export default {
    "backend": {
      "name": "git-gateway",
      "branch": "main"
    },
    "site_url": "https://healingwatersforwarriors.com",
    "display_url": "https://healingwatersforwarriors.com", 
    "identity_url": "https://healingwatersforwarriors.com/.netlify/identity", 
    "media_folder": "public/uploads",
    "public_folder": "/uploads",
    "collections": [
      {
        "name": "Gallery",
        "label": "Gallery",
        "label_singular": "Image",
        "folder": "content/gallery",
        "create": true,
        "slug": "{{slug}}",
        extension: "json",
        "fields": [
          {
            "label": "Title", 
            "name": "title", 
            "widget": "string", 
            "required": true
          },
          {
            "label": "Gallery Image", 
            "name": "image", 
            "widget": "image", 
            "required": true
          }
        ]
      },
      {
        "name": "Mission Statement", 
        "label": "Mission Statement", 
        "label_singular": "Image", 
        "folder": "content/mission",
        "create": true,
        "slug": "{{slug}}",
        extension: "json",
        "fields": [
          {
            "label": "Title", 
            "name": "title", 
            "widget": "string", 
            "required": true
          },
          {
            "label": "Mission Image", 
            "name": "image", 
            "widget": "image", 
            "required": true
          }
        ]
      }, 
      {
        "name": "Story", 
        "label": "Story", 
        "label_singular": "Image", 
        "folder": "content/story",
        "create": true,
        "slug": "{{slug}}",
        extension: "json",
        "fields": [
          {
            "label": "Title", 
            "name": "title", 
            "widget": "string", 
            "required": true
          },
          {
            "label": "Story Image", 
            "name": "image", 
            "widget": "image", 
            "required": true
          }
        ]
      },
      {
        "name": "Coverphoto", 
        "label": "Coverphoto",
        "label_singular": "Image",
        "folder": "content/coverphoto",
        "create": true,
        "slug": "{{slug}}",
        extension: "json",
        "fields": [
          {
            "label": "Title", 
            "name": "title", 
            "widget": "string", 
            "required": true
          },
          {
            "label": "Cover Image", 
            "name": "image", 
            "widget": "image", 
            "required": true
          }
        ]
      }
    ]
  }