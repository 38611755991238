import React from 'react';
import SectionHeader from '../../components/SectionHeader';

const Events = () => {
    
  return(
    <div style={{
      marginTop: '2em'
    }}>
      <SectionHeader HeaderTitle={"Events"}/>
      <div
        style={{
          margin: "4em",
          textAlign: "center"
        }}
      >
        Some events will be here... 
      </div>
    </div>
  )
}

export default Events;